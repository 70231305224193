/*** Feature Start ***/
.feature .feature-item {
  margin: 1rem;
  min-height: 30rem;
  width: 100%;
  position: relative;
  display: flex;
  border: 1px solid var(--bs-primary);
  border-radius: 10px;
  background: var(--bs-light);
  transition: 0.5s;
}

.feature .feature-item::before {
  width: 0;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  transition: 0.5s;
}

.feature .feature-item:hover::before {
  width: 100%;
  background: var(--bs-primary);
}

.feature .feature-item .feature-icon {
  border-radius: 10px;
  transition: 0.5s;
}

.feature .feature-item:hover .feature-icon {
  position: relative;
  z-index: 2;
}

.feature .feature-item:hover .feature-content {
  position: relative;
  color: var(--bs-white);
  z-index: 2;
}

.feature .feature-item:hover .feature-content h5 {
  color: var(--bs-dark);
}
/*** Feature End ***/
