/*** About Start ***/
.about .about-img {
    position: relative;
    overflow: hidden;
    height: 100%;
    border-radius: 10px;
  }
  
  .about .about-img .about-img-inner {
    position: absolute;
    left: 0;
    bottom: 0;
    border: 10px solid;
    border-color: var(--bs-white) var(--bs-white) var(--bs-white) var(--bs-white);
    border-radius: 50%;
  }
  
  .about .about-img .about-experience {
    position: absolute;
    top: 125px;
    left: -125px;
    transform: rotate(-90deg);
    background: transparent;
    color: var(--bs-primary);
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 4px;
  }
  
  .img-about-size {
    height: 50rem;
  }
  
  .offering-title {
    padding: 20px;
    margin-top: 5%;
    display: grid;
    margin-bottom: 5%;
    justify-items: center;
  }
  
  .offer-img-size {
    height: 30rem;
  }
  /*** About End ***/
  