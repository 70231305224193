/* In App.css or your component's CSS file */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.slider-item {
  position: relative;
}

.slider-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.carousel-caption {
  position: absolute;
  bottom: 30%;
  left: 0;
  right: 0;
  color: white;
  padding: 20px;
  text-align: center;
}
