@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

.contact-us {
    background-color: white;
    padding-top: 20px;
}

.formcarry-container {
  box-sizing: border-box;
  margin: 40px auto 20px auto;
  padding: 0;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;

  width: 400px;

  /* NORMAL */
  --fc-border-color: #ECEFF9;
  --fc-normal-text-color: #0E0B3D;
  --fc-normal-placeholder-color: #B3B8D0;

  /* PRIMARY COLOR | HSL FORMAT*/
  --fc-primary-color-hue: 220;
  --fc-error-color-hue: 356;
  --fc-primary-hsl: var(--fc-primary-color-hue), 100%, 54%;
  --fc-error-hsl: var(--fc-error-color-hue), 100%, 54%;

  /* HOVER */
  --fc-field-hover-bg-color: #F7F9FC;
  --fc-border-hover-color: #DDE0EE;
  --fc-field-hover-text-color: #B3B8D0;

  --fc-border-active-color: #1463FF;
}

.formcarry-container * {
  box-sizing: border-box;
}

.contact-us-img {
    margin: 40px auto 20px auto;
}

.formcarry-container label {
  display: block;
  cursor: pointer;
  color: black;
}

.demo-introduce {
  margin: 10px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.formcarry-container .formcarry-block:not(:first-child) {
  margin-top: 16px;
}

/*=============================================
=            Fields           =
=============================================*/

.formcarry-container input,
.formcarry-container textarea,
.formcarry-container select {
  margin-top: 4px;
  width: 100%;
  height: 42px;
  border: 1px solid var(--fc-border-color);
  box-shadow: 0 1px 2px var(--fc-border-color);
  color: var(--fc-normal-text-color);
  background: #fff;
  border-radius: 10px;
  padding: 8px 12px;

  font-family: "Inter", sans-serif;
  font-size: 14px;
  transition: 125ms background, 125ms color, 125ms box-shadow;
}

.formcarry-container textarea {
  min-height: 188px;
  max-width: 100%;
  padding-top: 12px;
}

.formcarry-container input::placeholder,
.formcarry-container textarea::placeholder,
.formcarry-container select::placeholder {
  color: var(--fc-normal-placeholder-color);
}

.formcarry-container input:hover,
.formcarry-container textarea:hover,
.formcarry-container select:hover {
  border-color: var(--fc-border-hover-color);
  background-color: var(--fc-field-hover-bg-color);
}

.formcarry-container input:hover::placeholder,
.formcarry-container textarea:hover::placeholder,
.formcarry-container select:hover::placeholder {
  color: var(--fc-field-hover-text-color);
}

.formcarry-container input:focus,
.formcarry-container textarea:focus,
.formcarry-container select:focus {
  background-color: #fff;
  border: 2px solid hsl(var(--fc-primary-hsl));
  box-shadow: hsla(var(--fc-primary-hsl), 8%) 0px 0px 0px 3px;
  outline: none;
}

.formcarry-container input:focus,
.formcarry-container select:focus {
  padding: 8px 11px;
}

.formcarry-container textarea:focus {
  padding: 11px;
}

.formcarry-container select {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.9997 14.5001L8.46387 10.9642L9.64303 9.78589L11.9997 12.1434L14.3564 9.78589L15.5355 10.9642L11.9997 14.5001Z' fill='%236C6F93'/%3E%3C/svg%3E%0A");
  /* background-position: calc(100% - 20px) calc(1em + 4px), calc(100% - 15px) calc(1em + 4px); */
  background-size: 24px 24px;
  background-position: 98%;
  background-repeat: no-repeat;
  appearance: none;
  -webkit-appearance: none;
}

.formcarry-container button {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.02em;
  height: 40px;
  line-height: 24px;
  width: 100%;
  border: 0;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: hsla(var(--fc-primary-hsl));
  color: #fff;
  cursor: pointer;
  box-shadow: 0 0 0 0 transparent;

  display: flex;
  justify-content: center;
  align-items: center;
  transition: 125ms all;
}

.formcarry-container button:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), hsla(var(--fc-primary-hsl));
}

.formcarry-container button:focus {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), hsla(var(--fc-primary-hsl));
  border-inline: 1px solid inline rgba(255, 255, 255, 0.6);
  box-shadow: 0px 0px 0px 3px rgba(var(--fc-primary-hsl), 12%);
}

.formcarry-container button:active {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), hsla(var(--fc-primary-hsl));
}

.formcarry-container button:disabled {
  background-color: hsla(var(--fc-primary-hsl), 40%);
  cursor: not-allowed;
}

.formcarry-container input:focus:required:invalid,
.formcarry-container input:focus:invalid,
.formcarry-container select:focus:required:invalid,
.formcarry-container select:focus:invalid,
.formcarry-container textarea:focus:invalid,
.formcarry-container textarea:focus:required:invalid,
.formcarry-container .fc-field-error {
  color: hsl(var(--fc-error-hsl));
  border-color: hsl(var(--fc-error-hsl));
  box-shadow: 0px 0px 0px 3px hsla(var(--fc-error-hsl), 12%);
}

/*=====  End of Fields  ======*/

.formcarry-message-block {
  display: none;

  background: linear-gradient(180deg, rgba(255, 255, 255, 0.18) 0%, rgba(255, 255, 255, 0.00) 100%), #070B12;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15), 0px 4px 4px 0px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  padding: 12px 40px 12px 16px;

  gap: 8px;
  align-items: center;

  position: relative;
  color: #fff;
}

.formcarry-message-block.active {
  display: grid;
  grid-template-columns: 24px 1fr 24px;
}

.formcarry-message-block .fc-message-icon {
  display: block;
  width: 24px;
  height: 24px;
}

.formcarry-message-block.fc-error .fc-message-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M11 18H7.94101C7.64391 16.7274 6.30412 15.6857 5.75395 14.9992C4.65645 13.6297 4 11.8915 4 10C4 5.58172 7.58172 2 12 2C16.4183 2 20 5.58172 20 10C20 11.8925 19.3428 13.6315 18.2443 15.0014C17.6944 15.687 16.3558 16.7276 16.059 18H13V13H11V18ZM16 20V21C16 22.1046 15.1046 23 14 23H10C8.89543 23 8 22.1046 8 21V20H16Z' fill='%23F8FAFF'%3E%3C/path%3E%3C/svg%3E");
  fill: #fff;
  background-size: cover;
}

.formcarry-message-block.fc-success .fc-message-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23F8FAFF' d='M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z'%3E%3C/path%3E%3C/svg%3E");
  fill: #fff;
  background-size: cover;
}

.fc-message-close {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 10.5858L9.17157 7.75736L7.75736 9.17157L10.5858 12L7.75736 14.8284L9.17157 16.2426L12 13.4142L14.8284 16.2426L16.2426 14.8284L13.4142 12L16.2426 9.17157L14.8284 7.75736L12 10.5858Z' fill='rgba(255,255,255,1)'%3E%3C/path%3E%3C/svg%3E");
  width: 24px;
  height: 24px;

  position: absolute;
  top: calc(50% - 12px);
  right: 14px;
  cursor: pointer;
}

.fc-field-error-message {
  padding: 2px 0 0 0;
  color: hsl(var(--fc-error-hsl));
}

.files-dropzone {
  width: 100%;
  height: 160px;
  margin-top: 4px;
  background-color: #fff;
  border: 1px solid var(--fc-border-color);
  box-shadow: 0 1px 2px var(--fc-border-color);
  color: var(--fc-normal-text-color);
  border-radius: 10px;
  padding: 8px 12px;

  font-family: "Inter", sans-serif;
  font-size: 14px;
  transition: 125ms background, 125ms color, 125ms box-shadow;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.files-dropzone:before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  border: 1px dashed var(--fc-border-color);
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  border-radius: 8px;
}

.files-dropzone span {
  padding-left: 4px;
  color: hsla(var(--fc-primary-hsl));
}

.files-dropzone-active:before {
  border: 1px dashed hsla(var(--fc-primary-hsl));
}

.files-list {
  display: flex;
  margin-top: 12px;
  gap: 12px;
  flex-wrap: wrap;
}

.files-list-item {
  padding: 8px;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid var(--fc-border-color);
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
}

.files-list-item-preview {
  width: 60px;
  height: 60px;
  position: relative;
}

.files-list-item-preview-extension {
  width: 60px;
  height: 60px;
  border-radius: 4px;
  background: #ECEFF3;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  color: #A4ABB9;
}

.files-list img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
}

.files-list-item-content {
  display: flex;
  gap: 24px;
}

.files-list-item-content-item-1 {
  width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.files-list-item-content-item-2 {
  color: var(--fc-normal-placeholder-color);
}

.files-list-item-remove {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 10px;
  height: 10px;
  cursor: pointer;
  background: #fff;
  padding: 2px;
  border-radius: 4px;
  box-sizing: content-box;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L9 9M9 1L1 9' stroke='%23C1C7D0' style='stroke:%23C1C7D0;stroke:color(display-p3 0.7569 0.7804 0.8157);stroke-opacity:1;' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  background-size: 6px;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid var(--fc-border-color);
}